import axios from 'axios';
import ENVIRONMENT_VARIABLES from '../environment.config';
import {
    INPROGRESS,
    ERROR,
    ALL_PRODUCTS,
    SAVE_PLACE_ORDER,
    PLACE_ORDER,
    GET_ORDER
} from '../constants/actionTypes';

export const getAllProducts = () => {
    try {
        return (dispatch) => {
            dispatch({type: INPROGRESS});

            let api = {
                method: 'GET',
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/products",
            };
            axios(api).then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: ALL_PRODUCTS,
                        data: response.data
                    });
                }
            }).catch((error) => {
                if (error && error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
                    dispatch({type: ERROR, data: {error_msg: error.response.data.user_msg}});
                } else {
                    dispatch({type: ERROR, data: {error_msg: error.message.toString()}});
                }
            });

        }
    } catch (error) {
        alert(error.message.toString());
    }
};


export const savePlaceOrder = (placeOrder) => {
    try {
        return (dispatch) => {
            dispatch({type: INPROGRESS});
            dispatch({
                type: SAVE_PLACE_ORDER,
                data: placeOrder
            });
        }
    } catch (error) {
        alert(error.message.toString());
    }
};

export const placeOrder = (placeOrder) => {
    try {
        return (dispatch) => {
            dispatch({type: INPROGRESS});
            let api = {
                method: 'POST',
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/orders/checkout",
                data: placeOrder
            };
            axios(api).then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: PLACE_ORDER,
                        data: response.data
                    });
                }
            }).catch((error) => {
                if (error && error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
                    dispatch({type: ERROR, data: {error_msg: error.response.data.user_msg}});
                } else {
                    dispatch({type: ERROR, data: {error_msg: error.message.toString()}});
                }
            });
        }
    } catch (error) {
        alert(error.message.toString());
    }
};

export const getOrderDetail = (orderId) => {
    try {
        return (dispatch) => {
            dispatch({type: INPROGRESS});
            let api = {
                method: 'GET',
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/orders/" + orderId
            };
            axios(api).then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: GET_ORDER,
                        data: response.data
                    });
                }
            }).catch((error) => {
                if (error && error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
                    dispatch({type: ERROR, data: {error_msg: error.response.data.user_msg}});
                } else {
                    dispatch({type: ERROR, data: {error_msg: error.message.toString()}});
                }
            });
        }
    } catch (error) {
        alert(error.message.toString());
    }
};
