import React from 'react';

const PrivacyPolicy = () => {

    return (
        <div>

            <div className="wb_cont_inner">

                <div id="wb_element_instance194" className="wb_element wb_text_element"
                     style={{"text-align": "center"}}>

                    <h1 className="wb-stl-heading1" style={{"text-align": "center"}}>PRIVACY POLICY</h1>

                    <p className="wb-stl-normal" style={{"text-align": "center"}}></p>

                    <p>SofinaBeauty, We, Website, Us, Ours may refer to the same entity and used interchangeably and of
                        any of the services that the entities use to deliver the said services and their corresponding
                        respective terms of use, limitations and policy framework of which SofinaBeauty as a end user of
                        such services, online or otherwise, have no control over.</p>

                    <p></p>

                    <p>SofinaBeauty privacy statement describes the information we collect, how and why we collect it,
                        how long the we store such data, if ay all collected and stored, and who has access to it. This
                        policy will change from time to time according to the relevant laws governing online services,
                        the delivery of online merchant services. By using the services available via the SofinaBeauty
                        website and apps and links either via association or click through, you agree to be bound by
                        this policy.</p>

                    <p></p>

                    <p>&lt;&gt; Who we are &lt;&gt;</p>

                    <p></p>

                    <p>SOFINA BEAUTY PTE. LTD. is a Exempt Private Company Limited by Shares, incorporated on 20
                        September 2019 (Friday) in Singapore at the following address, MAIN SHOP: 492 Jurong West St
                        41#01-36 Singapore 640492</p>

                    <p></p>

                    <p>The Company's principal activity is beauty and other personal care services n.e.c. with beauty
                        salons and spas (including slimming, skin care and hair care centres) as the secondary
                        activity.</p>

                    <p></p>

                    <p>&lt;&gt; Information is collected, how and why &lt;&gt;</p>

                    <p></p>

                    <p>We do not collect, store or have access to any personal data as we do not have an online member
                        management system.<br/>
                        We store your e-mail address, the e-mail and any additional information you provided. We use
                        this information to answer your e-mail and/or to improve our website and apps.</p>

                    <p></p>

                    <p>Beauty products, services as part of the beauty regimes &amp; skin care / body shaping / facial
                        methodology are sold via third party stores. That third party store takes care of order
                        processing and delivery. Similar to how you purchase a book from a bookshop, not from the author
                        (us). Therefore we advise you to read the payment service gateway Privacy Policy.</p>

                    <p></p>

                    <p>For clarify, YOUR records are not stored by us. We do not have access to your payment details. We
                        are merely informed on the outcome (yes / no) of the payment for the said items via an
                        electronic invoices has been successful paid for and/or debited from the client’s chosen payment
                        option. We then use this outcome to prepare the items as listed in the invoices for postal
                        delivery or package awaiting your collection at the shop.</p>

                    <p></p>

                    <p>&lt;&gt; How long is the information stored &lt;&gt;</p>

                    <p></p>

                    <p>We will hold this outcome on our systems for as long as is necessary for the relevant
                        activity.</p>

                    <p></p>

                    <p>&lt;&gt; Who has access to your information &lt;&gt;</p>

                    <p></p>

                    <p>We do not sell or rent the outcome information to third parties. And since we do not collect any
                        of your information, we do not sell to third parties for marketing purposes. And when you
                        contact us via e-mail as Feedback / Queries: Any questions regarding this policy and our privacy
                        practices, feedback and/or queries, should be sent to website_support_information_please "the @
                        sign" sofinabeauty.com. Please note that this is an unmanned email box and queries will be auto
                        routed to the support team. If you do not receive timely acknowledgment of your email from us,
                        or if we have not addressed your contents to your satisfaction, please write to us again or
                        visit the MAIN SHOP. The email system ignores repeated request from the same email sent within a
                        period of 7 days. From the queries we received by the unmanned email box, we will not contact
                        you for marketing purposes.</p>

                    <p></p>

                    <p>&lt;&gt; Cookies and nothing to do with Milk &lt;&gt;</p>

                    <p></p>

                    <p>We may Google Analytics, a third-party web analysis service provided by Google Inc., OR by any
                        such web hosting service provider, which uses “performance cookies” and “targeting cookies” to
                        analyse how you use the website. The information generated by the cookie about your use of the
                        website (including your IP address) will be transmitted to and stored by Google on servers in
                        the United States. However, this website uses Google Analytics with the expansion
                        ‘_anonymizeIp()’ which means that Google will truncate/anonymise the last octet of the IP
                        address for Member States of the European Union.</p>

                    <p></p>

                    <p>On behalf of us, Google will use the information collected for the purpose of evaluating your use
                        of our Website, compiling reports on website activity and providing other services relating to
                        website activity and internet usage to us. Google will not associate your IP address with any
                        other data held by Google. You may refuse the use of cookies by selecting the appropriate
                        settings on your browser. Furthermore you can prevent Google’s collection and use of data
                        (cookies and IP address) by downloading and installing the browser plug-in Google Analytics
                        Opt-out Browser Add-on.For more information about Google Analytics cookies, please see Google’s
                        help pages and privacy policy.</p>

                    <p></p>

                    <p>The web hosting provider:<br/>
                        Information that users provide through optional, voluntary submissions. Information Namecheap
                        gathers through aggregated tracking information derived mainly by tallying page views throughout
                        Our sites. This information allows Us to better tailor Our content to readers' needs and to help
                        Our advertisers and sponsors better understand the demographics of Our audience. Because
                        Namecheap derives its revenue mainly from sponsorships and advertising, providing such
                        aggregated demographic data is essential to keeping Our service free to users. Under no
                        circumstances does Namecheap divulge any information about an individual user to a third party.
                    </p>

                    <p><br/>
                        &lt;&gt; How you can access and update your information &lt;&gt;</p>

                    <p></p>

                    <p>As we do not store any information of the user to the website, there is no option for access nor
                        update. Security precautions that are in place to protect the loss, misuse or alteration of your
                        information as per the email we received / notices of the outcome. Again, as we do not store any
                        information of the user to the website, there is no option for access nor update. EXCEPT for the
                        emails and outcome notices which we receive, they are deleted from the servers based on relevant
                        email retention policies and as directed by the in-country legislation on the storage of
                        data.</p>

                    <p></p>

                    <p>&lt;&gt; Links to other websites &lt;&gt;</p>

                    <p></p>

                    <p>Our website may contain links to other websites such as Facebook / WeChat / <a
                        href="https://www.rapyd.net/dataprocessingaddendum/">Rapyd</a> / etc run by other organisations
                        which have their own privacy policy. They are not us and we are not them. So please read them.
                        We are not and cannot be responsible your actions after leaving this site.</p>

                    <p></p>

                    <p>We keep this Policy under regular review. This Policy was last updated 16 Oct, 2020.</p>

                    <p></p>

                    <p className="wb-stl-normal" style={{"text-align": "center"}}></p>

                </div>
            </div>
        </div>
    );

};

export default PrivacyPolicy;
