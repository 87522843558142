import React from 'react';
import ReactDOM from 'react-dom';
import {applyMiddleware, createStore} from 'redux';
import logger from 'redux-logger';
import promise from 'redux-promise';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import {Router, Route, browserHistory, IndexRoute} from 'react-router';
import {composeWithDevTools} from 'redux-devtools-extension';
import * as serviceWorker from './serviceWorker';
import rootReducer from './reducers';
import initialState from './reducers/initialState';
import NotFound from './Components/NotFound';
import Website from './Components/Website';
import Home from './Components/Home';
import AboutUs from './Components/AboutUs';
import InvitationOnly from './Components/InvitationOnly';
import Checkout from './Components/Checkout';
import Invoice from './Components/Invoice';
import TermsOfUse from './Components/TermsOfUse';
import PrivacyPolicy from './Components/PrivacyPolicy';
import DeliveryRefunds from './Components/DeliveryAndRefunds';
import 'bootstrap/dist/css/bootstrap.min.css';

const composeEnhancers = composeWithDevTools({});

const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk, promise)));

// const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk, promise, logger)));


ReactDOM.render(<Provider store={store}>
    <Router history={browserHistory}>
        <Route component={Website} path="/" exact={true}>
            <IndexRoute component={Home}/>
            <Route path="/AboutUs" component={AboutUs} exact={true}/>
            <Route path="/InvitationOnly" component={InvitationOnly} exact={true}/>
            <Route path="/Checkout" component={Checkout} exact={true}/>
            <Route path="/TermsOfUse" component={TermsOfUse} exact={true}/>
            <Route path="/PrivacyPolicy" component={PrivacyPolicy} exact={true}/>
            <Route path="/DeliveryRefunds" component={DeliveryRefunds} exact={true}/>
            <Route path="/Success/:orderId" component={Invoice} exact={true}/>
        </Route>
        <Route path="*" component={NotFound} exact={true}/>
    </Router>
</Provider>, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
