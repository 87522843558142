import React from 'react';
import Carousel from 'react-bootstrap/Carousel'

const Slider = () => {

    return (
        <div>
            <section className="w-100">
                <div className="main-slider position-relative">
                    <Carousel pause={false}>
                        <Carousel.Item>
                            <img className="d-block w-100"
                                 src={"/assets/images/slider1.png"}
                                 alt="First slide"
                            />
                            <Carousel.Caption>
                                <h2>WE ARE ONE</h2>
                                <p>Come celebrate with us GIFTS for EVERYONE</p>
                            </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                            <img className="d-block w-100"
                                 src={"/assets/images/slider2.png"}
                                 alt="Third slide"
                            />
                            <Carousel.Caption>
                                <h2>WE ARE ONE</h2>
                                <p>Come celebrate with us GIFTS for EVERYONE</p>
                            </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                            <img className="d-block w-100"
                                 src={"/assets/images/slider3.png"}
                                 alt="Third slide"
                            />
                            <Carousel.Caption>
                                <h2>WE ARE ONE</h2>
                                <p>Come celebrate with us GIFTS for EVERYONE</p>
                            </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                            <img className="d-block w-100"
                                 src={"/assets/images/slider4.png"}
                                 alt="Third slide"
                            />
                            <Carousel.Caption>
                                <h2>WE ARE ONE</h2>
                                <p>Come celebrate with us GIFTS for EVERYONE</p>
                            </Carousel.Caption>
                        </Carousel.Item>

                    </Carousel>
                </div>
            </section>
        </div>

    );


};
export default Slider;
