import React from 'react';

const DeliveryAndRefunds = () => {

    return (

        <div>
            <div className="wb_cont_inner">

                <div id="wb_element_instance218" className="wb_element wb_text_element"
                     style={{"line-height": "normal"}}>
                    <h1 className="wb-stl-heading1" style={{"text-align": "center"}}>Refund and Delivery</h1>

                    <p className="wb-stl-normal" style={{"text-align": "center"}}></p>

                    <h3 className="wb-stl-heading3" style={{"text-align": "center"}}>REFUND POLICY</h3>

                    <p></p>

                    <p>AS WITH THE CUSTOMERS AT THE MAIN SHOP, we adhere strictly to this refund process.</p>

                    <p></p>

                    <p><strong>FOR ITEMS SOLD ON DISCOUNT</strong>: THERE IS NO REFUND / NO EXCHANGE.<br/><strong>FOR
                        ITEMS THAT HAVE BEEN OPENED OR USED</strong>: THERE IS NO REFUND / NO EXCHANGE.</p>

                    <p></p>

                    <p><strong>FOR ALL OTHER ITEMS SOLD / OR ITEMS RECEIVED AS GIFTS / OR ITEMS AS PART OF THE BEAUTY
                        PACKAGE</strong>:</p>

                    <p></p>

                    <p><em>For the SofinaBeauty Exclusive Range of Products:</em></p>

                    <p>UNOPENED and UNSEALED and still in original packaging, a one for one exchange or exchange for any
                        other product or multiple products as long as the total price is not more than the price of the
                        original item.</p>

                    <p></p>

                    <p><em>For all generic brands (such as Medipeel / L’oreal / Acwell / Klairs &amp; Etude etc)</em>
                    </p>

                    <p>Additional requirement: Proof of payment at MAIN SHOP (nets / credit card statement / printed
                        receipt) is required.</p>

                    <p><br/><em>For online purchases:</em></p>

                    <p>Simply return the UNOPENED / UNSEALED item together with the proof of payment, via <a
                        href="https://www.grab.com/sg/express/">GrabExpress Bike Return Trip Fare</a> and we will
                        replace the item immediately or write to us to pick and choose the items valued not more than
                        the price of the original item.</p>

                    <p></p>

                    <p></p>

                    <p></p>

                    <p></p>

                    <p></p>

                    <p></p>

                    <h3 className="wb-stl-heading3" style={{"text-align": "center"}}>Online purchase Delivery</h3>

                    <p></p>

                    <p style={{"text-align": "center"}}>SofinaBeauty has numerous affiliate salons which you can self
                        pick up
                        your order. NO MINIMUM purchase required.</p>

                    <p></p>

                    <p style={{"text-align": "center"}}>MAIN SHOP / BUGIS (above MRT) / ALJUNIED (next to MRT).</p>

                    <p style={{"text-align": "center"}}></p>

                    <p style={{"text-align": "center"}}>Simply choose “pick up + location” and no delivery fees will be
                        added
                        and your package will be ready for self pick up.</p>

                    <p></p>

                    <p></p>

                    <p style={{"text-align": "center"}}><em><strong>For orders with delivery within
                        Singapore</strong></em>
                    </p>

                    <p style={{"text-align": "center"}}></p>

                    <p style={{"text-align": "center"}}>For orders above SGD230, FREE SAME DAY GrabExpress Bike service
                        or
                        similar to anywhere in Singapore.</p>

                    <p style={{"text-align": "center"}}></p>

                    <p style={{"text-align": "center"}}>For orders below SG230, (orders must be received before
                        10am)<br/>
                        Singapore Post Economy around 2 days: SG$6<br/>
                        Singapore Post Standard at least 1 day: SG$10</p>

                    <p style={{"text-align": "center"}}></p>

                    <p style={{"text-align": "center"}}>Optional Gift wrap service: please add SG$25 for box, wrapping
                        paper
                        and bow. Comes with FREE SAME DAY delivery service.</p>

                    <p style={{"text-align": "center"}}></p>

                    <p style={{"text-align": "center"}}></p>

                    <p style={{"text-align": "center"}}><em>For deliveries in China / Vietnam &amp; Malaysia, please
                        choose
                        “pick up + location” option and </em></p>

                    <p style={{"text-align": "center"}}><em>we will arrange for in-country delivery upon receiving your
                        full
                        payment.</em></p>

                    <p style={{"text-align": "center"}}></p>

                    <p style={{"text-align": "center"}}>PLEASE KINDLY NOTE: SofinaBeauty is not ready to ship
                        worldwide.</p>

                    <p></p>

                    <p className="wb-stl-normal" style={{"text-align": "center"}}></p>

                </div>
            </div>
        </div>

    );


};
export default DeliveryAndRefunds;
