import React from 'react';

const Footer = (props) => {

    return (
        <div>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-12">
                            <img src={"/assets/images/map.jpg"} alt="map image" className="map-img"/>
                        </div>
                        <div className="col-md-7 col-12 pl-2 pl-md-0">
                            <h2>我们就在这! Find us here!</h2>
                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                <img src={"/assets/images/footer-img.jpg"} alt="footer image" className="footer-img"/>
                                <div className="footer-address">
                                    <p className="m-0">电话 (+65) 9664.5888 WhatsApp</p>
                                    <a href="https://www.facebook.com/sophie.yu.138">https://www.facebook.com/sophie.yu.138</a>
                                    <p>Sofina Beauty Address:</p>
                                    <p className="m-0">492 Jurong West St 41 裕廊西</p>
                                    <p>#01-36 Singapore 640492</p>
                                    <p>bus: 98B, 98M, 99, 157, 185, 187, 198, 334, 502, 657, NR5 OR Just Grab !</p>
                                    <div className="d-flex align-items-center footer-link flex-wrap fs-14">©
                                        2019-2020
                                        <a href="/">sofinabeauty</a>
                                        <a href="https://www.sofinabeauty.com/Terms-of-Use/">Terms of Use </a>
                                        <a href="https://www.sofinabeauty.com/Privacy-Policy/">Privacy Policy</a>
                                        <a href="https://www.sofinabeauty.com/Delivery-and-Refunds/">Delivery & Refunds</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );

};

export default Footer;
