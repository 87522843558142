import React from 'react';


const TermsOfUse = () => {

    return (

        <div>

            <div className="wb_cont_inner">

                <div id="wb_element_instance170" className="wb_element wb_text_element" style={{"line-height": "normal"}}>

                    <h1 className="wb-stl-heading1" style={{"text-align": "center"}}>TERMS OF USE</h1>


                    <p className="wb-stl-normal" style={{"text-align": "center"}}></p>

                    <p>SofinaBeauty, We, Website, Us, Ours may refer to the same entity and used interchangeably and of
                        any of the services that the entities use to deliver the said services and their corresponding
                        respective terms of use, limitations and policy framework of which SofinaBeauty as a end user of
                        such services, online or otherwise, have no control over.</p>

                    <p></p>

                    <p>By using any in part or in full of the services via the online site operated by or for and on
                        behalf of SofinaBeauty, you agree to be bound by the following Terms of Use; These terms will
                        change from time to time according to the relevant laws governing online services, the delivery
                        of online merchant service and by using the services available via the SofinaBeauty online page
                        and apps and links either via association or click through or via <a
                            href="https://www.rapyd.net/privacypolicy/">payment merchants</a> accounts, you agree to be
                        bound by this terms of use policy.</p>

                    <p></p>

                    <p style={{"text-align": "center"}}>TERMS AND CONDITIONS</p>


                    <p></p>

                    <p>SofinaBeauty and her officers, directors, owners, employees, agents, representatives, suppliers
                        and service providers mentioned in this website are for informational purposes only. Use of and
                        access to this site and the information, materials, services, and other content available on or
                        through the site are subject to these terms of use and all applicable laws and are provided for
                        YOUR PERSONAL CONSUMPTION / NON-COMMERCIAL USE ONLY.</p>

                    <p></p>

                    <p style={{"text-align": "center"}}>NO INVESTMENT ADVICE / DISCLAIMER OF WARRANTIES</p>

                    <p></p>

                    <p>The Content is for informational purposes only, there is no such intention for the information or
                        other material to be construed for use as legal, tax, investment, financial, or other advice. We
                        are not seeking your investment into us. WE DO NOT and HAVE NO MLM SALES. We are making
                        available beauty products / services &amp; suggestions for personal information. All Content on
                        this site is information of a general nature and does not address the circumstances of any
                        particular individual or entity. ALTHOUGH we try our reasonable best to provide accurate and
                        up-to-date information for the products / services and other such materials on the site, the
                        information is suggested only, without any guarantee, warranty, representation or endorsement of
                        any kind as to its accuracy or completeness or otherwise (whether express or implied), it does
                        not constitute legal advice.</p>

                    <p></p>

                    <p style={{"text-align": "center"}}>USE AT YOUR OWN RISK</p>

                    <p></p>

                    <p>THE SITE AND CONTENT, ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND. YOU BEAR ALL RISKS
                        ASSOCIATED WITH THE USE OF THE SITE AND CONTENT, INCLUDING WITHOUT LIMITATION, ANY RELIANCE ON
                        THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY CONTENT AVAILABLE ON THE SITE.</p>

                    <p></p>

                    <p>YOU ALSO AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR ANY CONTENTS THAT IS POSTED / LINKED OR
                        TRANSMITTED BY YOU ON THROUGH OR FROM THIS SITE AND YOU AGREE TO BE RESPONSIBLE FOR ANY CAUSE,
                        DIRECTLY OR INDIRECTLY, ALLOW OTHERS TO CAUSE NUISANCE ANNOYANCE AND OR INCONVENIENCE TO THE
                        USERS OF THIS SITE OR AT THE MAIN SHOP THROUGH YOUR ACTIONS OR INFLUENCE THROUGH SOCIAL MEDIA,
                        MEDIA PRINTED OT OTHERWISE.</p>

                    <p></p>

                    <p style={{"text-align": "center"}}>LIMITATION OF LIABILITY</p>

                    <p></p>

                    <p>This information on the online server is maintained, controlled and operated from the MAIN SHOP
                        via instructions either verbal or otherwise to intermediaries directly or indirectly and we make
                        no representation that the data eventually included in any of the pages (linked or otherwise)
                        are appropriate or available for use in all/ any parts of the world. YOUR EXCLUSIVE REMEDY FOR
                        DISSATISFACTION WITH THE SITE AND CONTENT IS TO STOP USING THE SITE AND CONTENT. WE CAN NOT BE
                        LIABLE FOR DAMAGES OF ANY KIND RESULTING FROM YOUR USE OF OR INABILITY TO USE THE SITE OR
                        CONTENT.</p>

                    <p><br/>
                        Any dispute arising out of or in connection with this service shall be referred to and finally
                        resolved by arbitration administered by the Singapore International Arbitration Centre (“SIAC”)
                        in accordance with the Arbitration Rules of the Singapore International Arbitration Centre
                        ("SIAC Rules") for the time being in force, which rules are deemed to be incorporated by
                        reference in this Terms of Use. Or <a href="https://www.case.org.sg">CASE</a>, whichever fits
                        better.</p>

                    <p></p>

                    <p style={{"text-align": "center"}}>This page was last updated 16 Oct 2020.</p>

                    <p></p>

                    <p className="wb-stl-normal" style={{"text-align": "center"}}></p>


                </div>
            </div>
        </div>);
};

export default TermsOfUse;
