import React, {useState, useEffect} from 'react';
import Slider from '../Slider';
import ProductSlider from '../ProductSlider';
import {connect, useSelector} from "react-redux";
import Config from '../../environment.config';
import {browserHistory} from "react-router";
import swal from 'sweetalert';
import {bindActionCreators} from "redux";
import * as websiteAction from "../../actions/websiteAction";

const InvitationOnly = (props) => {

    const [allProducts, setAllProducts] = useState([]);
    const [placeOrder, setPlaceOrder] = useState(null);

    const websiteReducers = useSelector((state) => ({
        allProducts: state.websiteReducer.allProducts,
        placeOrder: state.websiteReducer.placeOrder
    }));

    const onChange = (event, product) => {
        let value = event.target.value;
        if (parseInt(value) >= 0) {
            let commonData = [...allProducts];
            let findProduct = commonData.find((singleData) => singleData._id === product._id);
            findProduct.Qty = parseInt(value);
            findProduct.Total = Number(findProduct.Price * value).toFixed(2);
            setAllProducts(commonData);
        }else{
            let commonData = [...allProducts];
            let findProduct = commonData.find((singleData) => singleData._id === product._id);
            findProduct.Qty = value;
            findProduct.Total = Number(findProduct.Price * value).toFixed(2);
            setAllProducts(commonData);
        }
    };

    useEffect(() => {
        let allproducts = [];
        websiteReducers.allProducts.map((singleData) => {
            let Qty = 0;
            let Total = 0;
            let findAndUpdate = websiteReducers.placeOrder.Items.find((singleItem) => singleItem._id === singleData._id);
            if (findAndUpdate) {
                Qty = findAndUpdate.Qty;
                Total = findAndUpdate.Total;
            }
            allproducts.push({
                _id: singleData._id,
                Name: singleData.Name,
                Qty: Qty,
                Image: singleData.Image,
                Info: singleData.Info,
                Price: singleData.Price,
                Total: Total
            });
        });
        setAllProducts(allproducts);
    }, [websiteReducers.allProducts]);

    useEffect(() => {
        setPlaceOrder(websiteReducers.placeOrder);
        websiteReducers.placeOrder.Items.map((singleData) => {
            let findAndUpdate = allProducts.find((singleItem) => singleItem._id === singleData._id);
            if (findAndUpdate) {
                findAndUpdate.Qty = singleData.Qty;
            }
        })
    }, [websiteReducers.placeOrder]);

    const redirectUrl = (url) => {
        const findShoppingCartProduct = allProducts.filter((singleData) => singleData.Qty > 0);
        if (findShoppingCartProduct.length > 0) {
            placeOrder.Items = findShoppingCartProduct;
            props.actions.websiteAction.savePlaceOrder(placeOrder);
            browserHistory.push(url);
        } else
            swal('Oops...', "empty shopping cart", 'error');
    };

    return (
        <div>

            <Slider/>

            <section className="sofina-about mt-5 text-center">
                <div className="container">
                    <h2 className="main-title text-center">Sofina Beauty</h2>
                    <p>This page is by invitation only.</p>
                    <p>To our top1%. To our most valued customers.</p>
                    <p className="mb-4">Here you can find all our products which we'll personally deliver to you/ or use
                        at your next visit here with us.</p>
                    <a href="https://www.facebook.com/sophie.yu.138">https://www.facebook.com/sophie.yu.138</a>
                    <p>仅受邀请-专为我们顶级1％客户</p>
                    <p>我们最珍贵的客户</p>
                    <p>在这儿您可以找到所订购的产品，我们将亲自为您提供/或您的下届会议使用。</p>
                    <h2 className="main-title text-center mt-5 black-title">Our Products</h2>

                    {allProducts.map((singleData, index) => (
                        <div className="row mb-4" key={index}>
                            <div className="col-sm-3 col-12">
                                <img src={Config.Image_URL + singleData.Image} align="product" className="product-img"/>
                            </div>
                            <div className="col-sm-9 col-12 text-left">
                                <h1 className="product-title">{singleData.Name}</h1>
                                <p>{singleData.Info}</p>
                                <div className="form-group col-md-4 p-0 mb-2">
                                    <input type="number" name="qty" className="form-control" value={singleData.Qty}
                                           onChange={(event) => onChange(event, singleData)}/>
                                </div>
                                <p className="price">${singleData.Price}</p>
                            </div>
                        </div>
                    ))}
                    <div className="text-right">
                        <button className="btn btn-primary" onClick={() => redirectUrl("/checkout")}>proceed to
                            payment
                        </button>
                    </div>
                </div>
            </section>

            <section className="main-product mt-5">
                <div className="container">
                    <ProductSlider/>
                </div>
            </section>


            <section className="mt-5">
                <div className="bottom-about">
                    <div className="container">
                        <p className="text-center mb-3">We can’t travel. But we can bring the Alpine Escape Hideaway to
                            us!</p>
                        <p className="mb-3">Historically, a discreet and personalized spa experience in pure comfort,
                            privacy, artistry and luxury harmoniously, we have managed to bring into Singapore, the new
                            Spa Bar. Once we understand better thought going through the 5 steps:</p>
                        <div className="text-center mb-3">
                            <p>Wellness Screening</p>
                            <p>Personal Consultation</p>
                            <p>Skin Analysis</p>
                            <p>Treatment reagents</p>
                            <p>And scents combinations</p>
                        </div>
                        <p className="mb-3">We’ll propose a guaranteed, enjoyable way using natural ingredients and
                            transform your COVID home stays into marvellous bath soaks with matching scents and body
                            specific cosmetics. And as the smallest details count the most, you must visit to
                            believe. </p>
                    </div>
                </div>
            </section>

        </div>
    );

};

const mapDispatchToProps = dispatch => ({
    actions: {
        websiteAction: bindActionCreators(websiteAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(InvitationOnly);
