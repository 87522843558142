import React, {useEffect, useState} from 'react';
import './style.css';
import {connect, useSelector} from "react-redux";
import {browserHistory} from "react-router";
import {bindActionCreators} from "redux";
import * as websiteAction from "../../actions/websiteAction";
import swal from "sweetalert";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Checkout = (props) => {

    const [placeOrder, setPlaceOrder] = useState({
        Name: "",
        Address: "",
        Address1: "",
        PostalCode: "",
        Mobile: "",
        Email: "",
        Items: [],
        Total: 0,
        DeliveryType: "Delivery",
        OfferMessage: "",
        GiftCharge: 0,
    });

    const [deliveryType, setDeliveryType] = useState([
        {
            value: "Delivery",
            checked: true,
        },
        {
            value: "Self pickup",
            checked: false,
        },
        {
            value: "Gift",
            checked: false,
        }
    ]);

    const [offerType, setOfferType] = useState([
        {
            value: "SingPost Economy ($6)",
            price: 6,
            checked: true,
        },
        {
            value: "SingPost Standard ($10)",
            price: 10,
            checked: false,
        }
    ]);

    const [checkedTamsCondition, setCheckedTamsCondition] = useState(false);

    const websiteReducers = useSelector((state) => ({
        placeOrder: state.websiteReducer.placeOrder
    }));

    const handleTamsCondition = (event) => {
        setCheckedTamsCondition(event.target.checked);
    };

    useEffect(() => {
        if (websiteReducers.placeOrder.Items.length > 0) {
            let commonPlaceOrder = {...websiteReducers.placeOrder};
            let commonData = [...deliveryType];
            commonData.map((singleSlots) => {
                singleSlots.checked = false;
                if (singleSlots.value === commonPlaceOrder.DeliveryType) {
                    singleSlots.checked = true;
                }
            });
            let Total = 0;
            commonPlaceOrder.Items.map((singleItem) => {
                Total = Number(parseFloat(Total) + parseFloat(singleItem.Qty * singleItem.Price)).toFixed(2);
            });

            if (Total > 230) {
                commonPlaceOrder.OfferMessage = {
                    value: 'FREE SAME DAY GrabExpress Bike service or similar to anywhere in Singapore.',
                    price: 0
                };
            } else {
                commonPlaceOrder.OfferMessage = {
                    value: 'SingPost Economy ($6)',
                    price: 6
                };
            }

            let commonOfferData = [...offerType];
            commonOfferData.map((singleSlots) => {
                singleSlots.checked = false;
                if (singleSlots.value === commonPlaceOrder.OfferMessage.value) {
                    singleSlots.checked = true;
                }
            });

            commonPlaceOrder.Total = Total;
            setPlaceOrder(commonPlaceOrder);
            setDeliveryType(commonData);
            setOfferType(commonOfferData);
        } else {
            browserHistory.push("/InvitationOnly");
        }
    }, [websiteReducers.placeOrder]);

    const handleChange = (event) => {
        const field = event.target.name;
        let commonData = {...placeOrder};
        commonData[field] = event.target.value;
        return setPlaceOrder(commonData);
    };

    const OnChange = (select) => {
        let commonData = [...deliveryType];
        let commonPlace = {...placeOrder};
        commonData.map((singleSlots) => {
            singleSlots.checked = false;
            if (singleSlots.value === select.value) {
                singleSlots.checked = true;
                commonPlace.DeliveryType = select.value;
                if (select.value === "Gift") {
                    commonPlace.OfferMessage = {
                        value: 'SingPost Economy ($6)',
                        price: 0
                    };
                    commonPlace.GiftCharge = 25;
                }
                else if (select.value === "Self pickup") {
                    commonPlace.GiftCharge = 0;
                    commonPlace.OfferMessage = {
                        value: 'Self Pickup',
                        price: 0
                    };
                } else {
                    commonPlace.GiftCharge = 0;
                    if (commonPlace.Total > 230) {
                        commonPlace.OfferMessage = {
                            value: 'FREE SAME DAY GrabExpress Bike service or similar to anywhere in Singapore.',
                            price: 0
                        };
                    } else {
                        commonPlace.OfferMessage = {
                            value: 'SingPost Economy ($6)',
                            price: 6
                        };
                    }
                }
                let commonOfferData = [...offerType];
                commonPlace.OfferMessage && commonOfferData.map((single) => {
                    single.checked = false;
                    if (single.value === commonPlace.OfferMessage.value) {
                        single.checked = true;
                    }
                });
                setOfferType(commonOfferData);
            }
        });
        setDeliveryType(commonData);
        setPlaceOrder(commonPlace);
    };

    const OnChangeOfferType = (select) => {
        let commonData = [...offerType];
        let commonPlace = {...placeOrder};
        commonData.map((singleSlots) => {
            singleSlots.checked = false;
            if (singleSlots.value === select.value) {
                singleSlots.checked = true;
                commonPlace.OfferMessage = select;
            }
        });
        setOfferType(commonData);
        setPlaceOrder(commonPlace);
    };


    const submitOrder = () => {
        if (placeOrder.Name !== "" && placeOrder.Email !== "" && placeOrder.Mobile !== "" && placeOrder.PostalCode !== "" && placeOrder.Address1 !== "")
            props.actions.websiteAction.placeOrder(placeOrder);
        else
            swal('Oops...', "All Fields are required", 'error');
    };

    return (

        <div className="container register">
            <div className="row d-flex justify-content-center">
                <div className="col-md-9 register-right">
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane d-block active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <h3 className="register-heading">Delivery Details</h3>
                            <div className="row col-md-12 register-form">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Full Name"
                                               name="Name"
                                               value={placeOrder.Name} onChange={handleChange}/>
                                    </div>
                                </div>
                                {/*<div className="col-md-12">*/}
                                {/*<div className="form-group">*/}
                                {/*<textarea className="form-control" placeholder="Billing Address"*/}
                                {/*value={placeOrder.Address}*/}
                                {/*name="Address"*/}
                                {/*rows="3" onChange={handleChange}/>*/}
                                {/*</div>*/}
                                {/*</div>*/}

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea className="form-control" placeholder="Delivery Address"
                                                  value={placeOrder.Address1}
                                                  name="Address1"
                                                  rows="3" onChange={handleChange}/>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="number" className="form-control" placeholder="Postal Code"
                                               name="PostalCode"
                                               value={placeOrder.PostalCode} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Mobile"
                                               name="Mobile"
                                               value={placeOrder.Mobile} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder="Email"
                                               name="Email"
                                               value={placeOrder.Email} onChange={handleChange}/>
                                    </div>
                                </div>

                                <div className="ml-4 col-md-12 d-flex align-items-center">
                                    <div className="row">
                                        <label className="mr-3 mb-0">Delivery Type: </label>
                                        {deliveryType.map((singleData, index) => (
                                            <div className="form-check form-check-inline" key={index}>
                                                <input className="form-check-input"
                                                       type="radio"
                                                       name="RadioOptions"
                                                       value={singleData.value}
                                                       key={index}
                                                       checked={singleData.checked}
                                                       onChange={() => OnChange(singleData)}/>
                                                <label className="form-check-label font-w400">{singleData.value}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <br/>

                                {(placeOrder.Total <= 230 && placeOrder.DeliveryType === "Delivery") ?
                                    <div className="ml-4 col-md-12 d-flex align-items-center">
                                        <div className="row">
                                            <label className="mr-3 mb-0">Choose : </label>
                                            {offerType.map((singleData, index) => (
                                                <div className="form-check form-check-inline" key={index}>
                                                    <input className="form-check-input"
                                                           type="radio"
                                                           name="OfferType"
                                                           value={singleData.value}
                                                           key={index}
                                                           checked={singleData.checked}
                                                           onChange={() => OnChangeOfferType(singleData)}/>
                                                    <label
                                                        className="form-check-label font-w400">{singleData.value}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div> : <div className="ml-4 col-md-12 d-flex align-items-center">
                                        {(placeOrder.OfferMessage && placeOrder.DeliveryType !== "Gift") &&
                                        <div className="row">
                                            <label className="mr-3 mb-0"> {placeOrder.OfferMessage.value} </label>
                                        </div>}
                                    </div>}

                                <div className="col-md-12 d-flex justify-content-end mt-4">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checkedTamsCondition}
                                                onChange={handleTamsCondition}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label={<span>I have read and accepted the <a
                                            href={"https://www.sofinabeauty.com/Delivery-and-Refunds/"}> <b> Delivery
                                            and
                                            Refunds </b> </a> conditions </span>}
                                    />
                                    <button type="submit" className="btn btn-primary submit"
                                            disabled={!checkedTamsCondition}
                                            onClick={submitOrder}>Pay
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 tab tableScroll">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    <th>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {placeOrder.Items.map((singleData) => (
                                    <tr key={singleData._id}>
                                        <td>{singleData.Name}</td>
                                        <td>{Number(singleData.Price).toFixed(2)}</td>
                                        <td>{singleData.Qty}</td>
                                        <td>{singleData.Total}</td>
                                    </tr>
                                ))}

                                {placeOrder.GiftCharge > 0 && (
                                    <tr>
                                        <td>{"GIFT"}</td>
                                        <td>{Number(placeOrder.GiftCharge).toFixed(2)}</td>
                                        <td>{1}</td>
                                        <td>{Number(placeOrder.GiftCharge).toFixed(2)}</td>
                                    </tr>
                                )}

                                {(placeOrder.OfferMessage !== "" && placeOrder.OfferMessage !== null && placeOrder.OfferMessage.price > 0) && (
                                    <tr>
                                        <td>{placeOrder.OfferMessage.value}</td>
                                        <td>{Number(placeOrder.OfferMessage.price).toFixed(2)}</td>
                                        <td>{1}</td>
                                        <td>{Number(placeOrder.OfferMessage.price).toFixed(2)}</td>
                                    </tr>
                                )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    actions: {
        websiteAction: bindActionCreators(websiteAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(Checkout);
