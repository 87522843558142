import React, {useState} from 'react';
import ItemsCarousel from 'react-items-carousel';

const ProductSlider = () => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    return (
        <div style={{padding: `0 ${chevronWidth}px`}}>
            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={2}
                gutter={20}
                leftChevron={<button>{'<'}</button>}
                rightChevron={<button>{'>'}</button>}
                outsideChevron
                chevronWidth={chevronWidth}
            >
                <div style={{height: 200, background: '#EEE'}}>
                    <img src={"/assets/images/product5.jpg"} alt="product image"/>
                </div>
                <div style={{height: 200, background: '#EEE'}}>
                    <img src={"/assets/images/product2.jpg"} alt="product image"/>
                </div>
                <div style={{height: 200, background: '#EEE'}}>
                    <img src={"/assets/images/product3.jpg"} alt="product image"/>
                </div>
                <div style={{height: 200, background: '#EEE'}}>
                    <img src={"/assets/images/product4.jpg"} alt="product image"/>
                </div>
            </ItemsCarousel>
        </div>
    );
};

export default ProductSlider;
