import {
    INPROGRESS,
    ALL_PRODUCTS,
    ERROR,
    SAVE_PLACE_ORDER, PLACE_ORDER, GET_ORDER,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function websiteReducer(state = initialState.websiteReducer, action) {

    switch (action.type) {

        case INPROGRESS:
            return Object.assign({}, state, {
                error_msg: null,
                success_msg: null,
                loading: true,
            });

        case ERROR:
            return Object.assign({}, state, {loading: false, error_msg: action.data.error_msg});

        case ALL_PRODUCTS:
            return Object.assign({}, state, {
                error_msg: null,
                loading: false,
                allProducts: action.data
            });

        case SAVE_PLACE_ORDER:
            return Object.assign({}, state, {
                error_msg: null,
                loading: false,
                placeOrder: action.data
            });

        case PLACE_ORDER:
            window.location.replace(action.data.redirectUrl);
            return {
                ...state,
                error_msg: null,
                loading: false
            };

        case GET_ORDER:
            return {
                ...state,
                error_msg: null,
                loading: false,
                InvoiceOrder: action.data
            };

        default:
            return state;
    }
};
