import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import * as websiteAction from "../../actions/websiteAction";
import {connect, useSelector} from 'react-redux';
import {browserHistory, Link} from 'react-router';

let moment = require('moment-timezone');

const Invoice = (props) => {

    const [invoiceOrder, setInvoiceOrder] = useState(null);
    const websiteReducers = useSelector((state) => ({
        InvoiceOrder: state.websiteReducer.InvoiceOrder
    }));

    useEffect(() => {
        if (props.params.orderId !== null && props.params.orderId !== undefined) {
            props.actions.websiteAction.getOrderDetail(props.params.orderId);
        } else {
            browserHistory.push('/');
        }
    }, []);

    useEffect(() => {
        if (websiteReducers.InvoiceOrder) {
            setInvoiceOrder(websiteReducers.InvoiceOrder);
        }
    }, [websiteReducers.InvoiceOrder]);

    const redirectUrl = (url) => {
        browserHistory.push(url);
    };

    return (
        <div>
            {invoiceOrder && (
                <div>
                    <div className="paid-text">
                        <div className="container">
                            <h1>{invoiceOrder.PaymentStatus}</h1>
                            {invoiceOrder.PaymentStatus === "paid" ?
                                <p className="thank-text">Thank You <b>{invoiceOrder.Name}</b>!</p> : (
                                    <p className="thank-text">OPS <b>{invoiceOrder.Name}</b>! Your order is NOT
                                        complete!
                                    </p>)}
                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <td>Your order for the following is complete</td>
                                        <td>{moment.tz(invoiceOrder.Date, 'Asia/Singapore').format('DD-MM-YYYY hh:mm A')}</td>
                                    </tr>
                                    <tr>
                                        <td>You have chosen</td>
                                        <td>{invoiceOrder.DeliveryType}</td>
                                    </tr>
                                    <tr>
                                        <td>Your listed Delivery address is</td>
                                        <td>{invoiceOrder.Address1} {invoiceOrder.PostalCode}</td>
                                    </tr>
                                    {/*<tr>*/}
                                        {/*<td>Your listed Billing address is</td>*/}
                                        {/*<td>{invoiceOrder.Address}</td>*/}
                                    {/*</tr>*/}
                                    <tr>
                                        <td>Your listed mobile and email is</td>
                                        <td>{invoiceOrder.Mobile} {invoiceOrder.Email}</td>
                                    </tr>
                                    <tr>
                                        <td>Order Reference Number</td>
                                        <td>{invoiceOrder._id}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <a onClick={() => redirectUrl("/InvitationOnly")} className="return-page pointer">Return
                                    To
                                    Product Page</a>
                            </div>
                        </div>

                        <div className="col-md-12 tab tableScroll">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    <th>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {invoiceOrder.Items.map((singleData) => (
                                    <tr key={singleData._id}>
                                        <td>{singleData.Name}</td>
                                        <td>{Number(singleData.Price).toFixed(2)}</td>
                                        <td>{singleData.Qty}</td>
                                        <td>{Number(singleData.Total).toFixed(2)}</td>
                                    </tr>
                                ))}

                                {invoiceOrder.GiftCharge > 0 && (
                                    <tr>
                                        <td>{"GIFT"}</td>
                                        <td>{Number(invoiceOrder.GiftCharge).toFixed(2)}</td>
                                        <td>{1}</td>
                                        <td>{Number(invoiceOrder.GiftCharge).toFixed(2)}</td>
                                    </tr>
                                )}

                                {(invoiceOrder.OfferMessage !== "" && invoiceOrder.OfferMessage !== null && invoiceOrder.OfferMessage.price > 0) && (
                                    <tr>
                                        <td>{invoiceOrder.OfferMessage.value}</td>
                                        <td>{Number(invoiceOrder.OfferMessage.price).toFixed(2)}</td>
                                        <td>{1}</td>
                                        <td>{Number(invoiceOrder.OfferMessage.price).toFixed(2)}</td>
                                    </tr>
                                )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

};


const mapDispatchToProps = dispatch => ({
    actions: {
        websiteAction: bindActionCreators(websiteAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(Invoice);
