import React, {useState, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import Loader from '../Loader';
import swal from 'sweetalert';
import Footer from '../Footer';
import * as websiteAction from "../../actions/websiteAction";
import {bindActionCreators} from "redux";
import {browserHistory, Link} from 'react-router';
import './style.css';

const Website = (props) => {

    const websiteReducers = useSelector((state) => ({
        loading: state.websiteReducer.loading,
        error_msg: state.websiteReducer.error_msg,
        success_msg: state.websiteReducer.success_msg,
    }));

    useEffect(() => {
        if (websiteReducers.error_msg !== null) {
            swal('Oops...', websiteReducers.error_msg.toString(), 'error');
        }
    }, [websiteReducers.error_msg]);

    useEffect(() => {
        if (websiteReducers.success_msg !== null) {
            swal("", websiteReducers.success_msg, "success");
        }
    }, [websiteReducers.success_msg]);

    useEffect(() => {
        props.actions.websiteAction.getAllProducts();
    }, []);

    const redirectUrl = (url) => {
        browserHistory.push(url);
    };

    return (
        <div>
            <header>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-6">
                            <a href="https://www.facebook.com/sophie.yu.138" className="main-logo">
                                SOFINA BEAUTY
                            </a>
                        </div>
                        <div className="col-6">
                            <ul className="main-menu d-flex align-items-center justify-content-end">
                                <li><a onClick={() => redirectUrl("/")} className="active pointer">Home</a></li>
                                <li><a onClick={() => redirectUrl("/AboutUs")} className="pointer">About Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            {props.children}
            <Footer/>
            {websiteReducers.loading && <Loader/>}
        </div>
    );

};

const mapDispatchToProps = dispatch => ({
    actions: {
        websiteAction: bindActionCreators(websiteAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(Website);
