import React, {useState, useEffect} from 'react';
import Slider from '../Slider';
import {browserHistory} from "react-router";

const Home = () => {

    const redirectUrl = (url) => {
        browserHistory.push(url);
    };

    return (
        <div>

            <Slider/>

            <section className="sofina-about mt-5 text-center">
                <div className="container">
                    <h2 className="main-title text-center">Sofina Beauty</h2>
                    <p>Thank you!! <a onClick={() => redirectUrl("/InvitationOnly")}
                                      className="d-inline-block pointer">INCREDIBLE</a> Phase 2
                        Re-Opening Support!</p>
                    <p className="mb-4">Please <b>Whatsapp +65 9664.5888</b> your time slots to avoid waiting at the
                        shop!</p>
                    <p className="mb-4">keep in mind: QR safe entry, safe distancing, masks</p>
                    <a href="https://www.facebook.com/sophie.yu.138">https://www.facebook.com/sophie.yu.138</a>
                    <p>新老朋友 感谢您的大力支持</p>
                    <p className="mb-4">请预订您的时间以避免在店里等待!</p>
                    <p>健康和安全第一</p>
                </div>
            </section>


            <section className="service mb-5">
                <div className="container">
                    <h2 className="main-title text-center mt-5 black-title">Our services</h2>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <img src={"/assets/images/service1.jpg"} alt="service image"/>
                            <h1>面膜 Facial Treatment</h1>
                        </div>
                        <div class="col-md-4 col-12">
                            <img src={"/assets/images/service2.jpg"} alt="service image"/>
                            <h1>眉毛 Eye lash / brow</h1>
                        </div>
                        <div class="col-md-4 col-12">
                            <img src={"/assets/images/service3.jpg"} alt="service image"/>
                            <h1>修指甲 Mani / Pedi</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mt-5 home-bottom-about mb-2">
                <div className="bottom-about">
                    <div className="container text-center">
                        <p>personalised treatment</p>
                        <p>for each skin type... <a onClick={() => redirectUrl("/AboutUs")} className="pointer">find out
                            more</a></p>
                    </div>
                </div>
            </section>

            <section className="gallery-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-12 col-lg-4">
                            <div className="gallery" id="gallery">
                                <a href={"/assets/images/g1.jpg"} className="pics" data-fancybox="images">
                                    <img src={"/assets/images/g1.jpg"} alt="image"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 col-lg-4">
                            <div className="gallery" id="gallery">
                                <a href={"/assets/images/g2.jpg"} className="pics" data-fancybox="images">
                                    <img src={"/assets/images/g2.jpg"} alt="image"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 col-lg-4">
                            <div className="gallery" id="gallery">
                                <a href={"/assets/images/g3.jpg"} className="pics" data-fancybox="images">
                                    <img src={"/assets/images/g3.jpg"} alt="image"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 col-lg-4">
                            <div className="gallery" id="gallery">
                                <a href={"/assets/images/g4.jpg"} className="pics" data-fancybox="images">
                                    <img src={"/assets/images/g4.jpg"} alt="image"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 col-lg-4">
                            <div className="gallery" id="gallery">
                                <a href={"/assets/images/g5.jpg"} className="pics" data-fancybox="images">
                                    <img src={"/assets/images/g5.jpg"} alt="image"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 col-lg-4">
                            <div className="gallery" id="gallery">
                                <a href={"/assets/images/g6.jpg"} className="pics" data-fancybox="images">
                                    <img src={"/assets/images/g6.jpg"} alt="image"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 col-lg-4">
                            <div className="gallery" id="gallery">
                                <a href={"/assets/images/g7.jpg"} className="pics" data-fancybox="images">
                                    <img src={"/assets/images/g7.jpg"} alt="image"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 col-lg-4">
                            <div className="gallery" id="gallery">
                                <a href={"/assets/images/g8.jpg"} className="pics" data-fancybox="images">
                                    <img src={"/assets/images/g8.jpg"} alt="image"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 col-lg-4">
                            <div className="gallery" id="gallery">
                                <a href={"/assets/images/g9.jpg"} className="pics" data-fancybox="images">
                                    <img src={"/assets/images/g9.jpg"} alt="image"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );

};
export default Home;
