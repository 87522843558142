import React from 'react';

const AboutUs = () => {

    return (
        <div>
            <section className="w-100 breadcrumb">
                <div className="container">
                    <h1>About us</h1>
                </div>
            </section>

            <section className="sofina-about mt-5 text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <h2 className="main-title">来让您自然美丽发亮!</h2>
                            <p className="mb-5">想接单根，开花，Y毛，直毛婴儿弯，彩睫各种颜色各种不同类型风格都有，量眼定制私人嫁接打造不一样的迷人双眼</p>
                            <span>眉毛的风水：</span>
                            <p className="mb-5"> 相学说：修眉修心，改眉改运， 眉断财断，眉散财散， 眉垂财空，眉缺财走， 上缺伤情，下缺伤财， 贵人残缺，孤立垂助， 眉高心高，眉低受欺，
                                红眉克亲，蓝眉伤己， 眉不过框，富贵不长， 眉宇上扬，气势刚强。</p>
                            <p>所以, 做眉改变的不只是你的形象，更是你的运气 （未做眉毛的宝宝们你们属于哪一类型）做了眉眼唇可以省很多时间，再也不用早早起床化妆，也不再担心流汗掉妆的后果.</p>
                        </div>
                        <div className="col-md-3 col-12">
                            <img src={"/assets/images/about1.jpg"} alt="image" className="w-100 inner-about-img"/>
                        </div>
                        <div className="col-md-3 col-12">
                            <img src={"/assets/images/about2.jpg"} alt="image" className="w-100 inner-about-img"/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service mb-5">
                <div className="container">
                    <h2 className="main-title text-center mt-5 black-title">Professional results</h2>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <img src={"/assets/images/pro1.jpg"} alt="service image"/>
                            <h1 className="professional-title">Beautiful Nails</h1>
                        </div>
                        <div className="col-md-4 col-12">
                            <img src={"/assets/images/pro2.jpg"} alt="service image"/>
                            <h1 className="professional-title">Lavious Lashes</h1>
                        </div>
                        <div className="col-md-4 col-12">
                            <img src={"/assets/images/pro3.jpg"} alt="service image"/>
                            <h1 className="professional-title">Glowing Kisses</h1>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

};

export default AboutUs;
