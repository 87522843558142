import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';

import websiteReducer from './websiteReducer';

const rootReducer = combineReducers({
    websiteReducer,
    routing: routerReducer
});

export default rootReducer;
