const ENV = "prod";

const ENVIRONMENT_LOCAL = {
    Base_API_URL: "http://localhost:9000/api",
    Image_URL: "http://localhost:9000/images/products/"
};

const ENVIRONMENT_DEVELOPMENT = {
    Base_API_URL: "http://localhost:9000/api",
    Image_URL: "http://localhost:9000/images/products/"
};

const ENVIRONMENT_PRODUCTION = {
    Base_API_URL: "https://webservices.sofinabeauty.com:9000/api",
    Image_URL: "https://webservices.sofinabeauty.com:9000/images/products/"
};

let ENVIRONMENT_VARIABLES;

if (ENV === "local") {
    ENVIRONMENT_VARIABLES = ENVIRONMENT_LOCAL;
} else if (ENV === "development") {
    ENVIRONMENT_VARIABLES = ENVIRONMENT_DEVELOPMENT;
} else {
    ENVIRONMENT_VARIABLES = ENVIRONMENT_PRODUCTION;
}

export default ENVIRONMENT_VARIABLES;
